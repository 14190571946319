import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Grid, Typography, Box, Stack, Divider} from "@mui/material";
import Header from "../components/Header";

export default function Main(){

    const [padding, setPadding] = useState('100px');

    useEffect(() => {
        const handleResize = () => {
            //Get height of header and update paddingTop for content
              let header = document.querySelector('.header');
              let height = header.offsetHeight;
              setPadding(height+'px');
        
        }

        // Attach the event listener to the window object
        window.addEventListener('resize', handleResize);
        handleResize();
    
        // Remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
      
        <div style={{width:'100%', position:'relative'}}>

            <Header />
           
            <Stack className='content' sx={{paddingTop:padding}}>

                {/* Tagline and Image */}

                <Stack className='main' sx={{paddingBottom:{xs:'15px', md:'0'}}}> 

                    {/* Mobile */}
                    <Grid container rowGap={1} justifyContent="space-evenly" alignItems="flex-end" sx={{textAlign:'center', display:{xs:'flex', md:'none', zIndex:'997'}}}>
                        <Grid item lg={12} sx={{position:'relative', height:'auto', backgroundRepeat:'no-repeat', padding:'10% 0 0 0', display:'flex', justifyContent:'center', 
                            alignItems:'flex-start'}}>
                            <img src='images/chargeBarge.png' draggable="false" style={{maxWidth:'700px', width:'90%'}}/>
                        </Grid>
                        <Grid item xs={12} sx={{  textAlign:'center', background: 'rgb(0 0 0 / 55%)', padding:{xs:'5%', sm:'3%'}, marginTop:'-15%', zIndex:'998'}}>
                            <Typography sx={{fontFamily:'Play', fontWeight:'400', fontSize:{xs:'1.25em', sm:'1.5em', md:'2em'}, color:'#C6C7C9', lineHeight:{xs:'15px', md:'20px'}}}>GSE electrification is rapidly evolving.</Typography>
                            <Typography sx={{fontFamily:'Play', fontWeight:'700', fontSize:{xs:'2em', sm: '2.5em', md:'3em'}, color:'#ffffff', paddingTop:'0', lineHeight:{xs:'25px', sm:'35px'}, marginTop:{xs:'10px', sm:'15px'}}}>DON'T LET YOUR CHARGING GET STUCK IN CONCRETE.</Typography>
                        </Grid>
                        
                    </Grid>      
                    
                    {/* Web  */}
                    <Grid container justifyContent="space-evenly" alignItems="stretch" sx={{textAlign:'center', display:{xs:'none', md:'flex'}, width:'80% !important', marginLeft:'10%'}} >
                 
                        <Grid item md={4} sx={{ textAlign:'center', padding:'2%', background: 'rgb(0 0 0 / 25%)'}}>
                            <Box sx={{height:'100%', textAlign:'center', justifyContent:'center', display:'flex', flexDirection:'column'}}>
                                <Typography sx={{fontFamily:'Play', fontWeight:'400', fontSize:{md:'1.25em', lg:'1.5em', xl:'2em'}, color:'#C6C7C9', lineHeight:{md:'20px', lg:'25px', xl:'30px'}}}>GSE electrification is rapidly evolving.</Typography>
                                <Typography sx={{fontFamily:'Play', fontWeight:'800', fontSize:{md:'1.75em', lg:'3em', xl:'3.25em'}, color:'#ffffff', lineHeight:{md:'25px', lg:'30px', xl:'40px'}, marginTop:{md:'15px', lg:'20px'}}}>DON'T LET YOUR CHARGING GET STUCK IN CONCRETE.</Typography>  
                            
                            </Box>
                        </Grid>
      
                        <Grid item md={8} sx={{position:'relative', height:'auto', backgroundRepeat:'no-repeat', padding:'4%', display:'flex', justifyContent:'center'}}>
                            <img src='images/chargeBarge.png' draggable="false" style={{maxWidth:'750px', width:'80%', marginTop:'4%'}}/>   
                        </Grid>
                        
                    </Grid> 
                </Stack>

                {/* Specs */}
                <Box sx={{padding:{xs:'5%', lg:'3% 10%'}, width:{xs:'calc(100% - 10%)',lg:'calc(100% - 20%)'}, backgroundColor:'#f0f0f0', color:'#444444'}}>
                    <Typography sx={{fontSize:{xs:'2em', lg:'3em'}, lineHeight:{xs:'1.2em', lg:'2em'}, fontWeight:'600', color:'rgb(0,82,181)', fontFamily:'Play'}}>Enabling Mobile Infrastructure</Typography>
                        
                        <Typography sx={{fontSize:{xs:'1em', sm:'1.1em', md:'1.3em'}, textAlign:'justify', paddingBottom:'0'}}>
                        Infrastructure can be challenging, requiring significant time for planning, site reviews, approvals, and construction. Further, what is 
                        implemented today may not be ideal for the operation in terms of layout, distance from gates, and more. Given how rapidly GSE equipment 
                        is evolving, it is difficult for airports to accurately predict the number of chargers needed for existing and future operations across 
                        airport owned vehicles and vehicles or GSE from various airlines. GridLink GSE is a mobile solution, packaged and towed like a common 
                        baggage cart, that allows you to charge your EVs wherever makes the most sense without being tethered to a power source. This opens the 
                        door for many energy distribution strategies, such as having a "fuel cart" for EVs on the ramp.
                        </Typography>

                        <Divider sx={{marginTop:'40px'}}/>
                    
                        <Typography sx={{fontSize:{xs:'2em', lg:'3em'}, lineHeight:{xs:'1.2em', lg:'2em'}, fontWeight:'600', color:'rgb(0,82,181)', fontFamily:'Play', marginTop:'40px'}}>Existing GSE Grid Compatibility</Typography>
                        <Typography sx={{fontSize:{xs:'1em', sm:'1.1em', md:'1.3em', marginTop:{xs:'10px', md:'0'}, textAlign:'justify'}}}>
                            With GridLink GSE you can charge J1772 or CCS1/2 ground support equipment using existing 60-120 VDC Euro or BIW connector infrastructure or 400 Hz power
                            from a bridge or GPU. With no infrastructure change you are now able to support all new and upcoming J1772, North American CCS1, European CCS2, or Tesla's NACS; these are quickly deployable for rapidly growing electric fleets
                            which adhere to on-highway charging standards.
                            <br /><br />
                            GridLink GSE also acts as an energy buffer to enable DC fast charging of equipment where existing infrastructure may be power limited. 
                            The GridLink GSE batteries can be charged slowly over a longer period of time on low current circuits, but can provide stored energy for DC 
                            fast charging. Taking advantage of a GSE equipment’s level 3 charging ability could help enable a full recharge in under an hour, depending on 
                            conditions, aiding many baggage and cargo fleets with tight schedules.                                
                        </Typography>

                    <Divider sx={{marginTop:'40px'}}/>   
                        
                    <Typography sx={{fontSize:{xs:'2em', lg:'3em'}, fontWeight:'600', color:'rgb(0,82,181)', fontFamily:'Play', marginTop:'40px'}}>Energy Cost Savings</Typography>
                        <Typography sx={{fontSize:{xs:'1em', sm:'1.1em', md:'1.3em'}, textAlign:'justify'}}>
                            Scheduling times for grid energy consumption is a key feature that GridLink GSE implements. With configurable programmed consumption 
                            time, GridLink GSE is able to draw most or all of the 156 kWh during off-peak times to reduce electricity costs and load-balance the 
                            grid during higher demand times without compromising when equipment is charged. This can be done on-site or remotely, as GridLink 
                            GSE is equipped with telemetry for equipment monitoring, battery state-of-charge, battery health, and energy data collecting and 
                            configuration.
                        </Typography>
                
                        <Divider sx={{marginTop:'40px'}}/>

                    <Typography sx={{fontSize:{xs:'2em', lg:'3em'}, fontWeight:'600', color:'rgb(0,82,181)', fontFamily:'Play', marginTop:'40px'}}>Going Green</Typography>               
                        <Typography sx={{fontSize:{xs:'1em', sm:'1.1em', md:'1.3em'}, textAlign:'justify'}}>
                            As part of PCS' commitment to a more sustainable future, the GridLink GSE features second life automotive batteries. These batteries have 
                            been used in automotive and are now suitable for the duty cycle of a mobile energy storage system. Using second life batteries for this 
                            versatile charging solution enables future expansion of EVs without consuming additional energy in the manufacturing of new batteries or 
                            mining of rare earth minerals.
                        </Typography>

                        <Divider sx={{marginTop:'40px'}}/>
                    
                        <Typography sx={{fontSize:{xs:'2em', lg:'3em'}, fontWeight:'600', color:'rgb(0,82,181)', fontFamily:'Play', marginTop:'40px'}}>Specifications</Typography>
                        <Typography sx={{fontSize:{xs:'1.2em', lg:'1.75em'}, fontWeight:'500', fontFamily:'Rubik', marginTop:'15px'}}>156 kWh Mobile Charging Trailer</Typography>
                        <Typography sx={{fontSize:{xs:'1em', sm:'1.1em', md:'1.3em'}}}>
                                <ul style={{color:"#48484"}}><li>Three independent 52 kWh charging banks</li></ul>
                        </Typography>

                        
                        <Typography sx={{fontSize:{xs:'1.2em', lg:'1.75em'}, fontWeight:'500', fontFamily:'Rubik', marginTop:{xs:'20px', md:'40px'}}}>Flexible Input Power Options</Typography>
                        <Typography sx={{marginTop:'10px', fontSize:{xs:'1em', sm:'1.1em', md:'1.3em', fontWeight:'400', paddingLeft:'20px'}}}>
                                <span style={{fontWeight:'500', color:'#484848'}}>Charge from existing GSE infrastructure</span><br />
                                <ul style={{marginTop:'5px', color:"#48484"}}>
                                    <li>400Hz 90 kVA Bridge or GPU Power</li>
                                    <li>60 - 100 VDC from GSE chargers with Euro or BIW Connector</li>
                                </ul>
                                <span style={{fontWeight:'500', color:'#484848'}}>Grid Inputs</span>
                                <ul style={{marginTop:'5px', color:"#48484"}}>
                                    <li>3ø 227/480V 100A</li>
                                    <li>3ø 120/208V 100A</li>
                                    <li>3ø 227/480V 60A</li>
                                    <li>3ø 250V 30A</li>
                                    <li>1ø 250V 30A</li>
                                    <li>1ø 125V 15A</li>
                                </ul>
                        </Typography>
                </Box>

                <Stack sx={{padding:{xs:'5%', lg:'5% 10%'}, width:{xs:'calc(100% - 10%)',lg:'calc(100% - 20%)'}}}>
                    <Grid container columnGap={1} sx={{display:'flex', justifyContent:{xs:'center', md:'space-evenly'}, alignItems:'center'}}>

                        <Grid item xs={12} md={6} sx={{textAlign:'center'}}>
                            <Typography sx={{fontSize:{xs:'1.75em', lg:'3em'}, lineHeight:{xs:'1.2em', lg:'2em'}, fontWeight:'600', color:'rgb(0,82,181)', fontFamily:'Rubik'}}>
                                International GSE Expo 2023 
                            </Typography>

                            <Typography sx={{fontSize:{xs:'1em', sm:'1.21em', md:'1.3em'}}}>
                                The GridLink GSE charging system will be unveiled at the 2023 International GSE Expo in Las Vegas, Nevada September 26<sup>th</sup> - 28<sup>th</sup>. To see it and all PCS has to offer, please register to attend now!
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={5} sx={{marginTop:{xs:'20px', md:'0'}, textAlign:{xs:'center', md:'left'} }}>
                            <Link to ="http://bit.ly/3mdH04N">
                                <img src="images/gseshow.jpg" style={{width:'90%'}}/>
                            </Link>
                        </Grid>
                    </Grid>
                </Stack>
                
                {/* Footer */}
                <Stack sx={{padding:{xs:'5% 10%', md:'2% 10%'}, width:'calc(100% - 20%)', backgroundColor:'#0057b8', color:'#FFFFFF'}}>
                    <Grid container columnGap={2} justifyContent="space-evenly" alignItems="center" sx={{textAlign:'center'}}>
                    <Grid item xs={12} sx={{fontSize:{xs:'1em', md:'1.3em'}, fontWeight:'400', fontFamily:'Roboto'}}>
                            &copy; 2023 Powertrain Control Solutions, LLC. All Rights Reserved.
                    </Grid>
                    </Grid>      
                </Stack> 
            </Stack>           
        </div>
    )
}