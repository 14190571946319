import React, { useState, useEffect } from "react";
import { Grid, Typography, Drawer, List, ListItem, Box, ListItemButton, Divider, ListItemText, ListItemIcon} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';


export default function Header(){
    
    // Sticky Menu Area
   /* useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });*/

    const [drawerOpen, setDrawerOpen] = useState(false);
      
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setDrawerOpen(open);
    };

           
    // /* Method that will fix header after a specific scrollable */
    // const isSticky = (e) => {
    //     const header = document.querySelector('.header');
    //     const scrollTop = window.scrollY;
    //     scrollTop >= 150 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    // };
    
    return (
        <>
            <Drawer
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                PaperProps={{sx: { width: "100%"}}}>
            
                <Box
                    sx={{width:'100% !important'}}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}>

                    <List sx={{padding:'0'}}>
                        <ListItem key="learnmore" sx={{padding:'0', margin:'0'}}>
                            <ListItemButton sx={{padding:'20px'}} href={`mailto:gsecharging@powertraincontrol.com`} onclick={toggleDrawer}>
                                <ListItemIcon>
                                    <MailIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contact Us" />
                            </ListItemButton>
                        </ListItem>
                        
                        <Divider />

                        <ListItem key="learnmore" sx={{padding:'0', margin:'0'}}>
                            <ListItemButton sx={{padding:'20px'}} href={`https://www.powertraincontrolsolutions.com`} onclick={toggleDrawer}>
                                <ListItemIcon>
                                    <LanguageIcon />
                                </ListItemIcon>
                                <ListItemText primary="Visit PCS" />
                            </ListItemButton>
                        </ListItem>

                        <Divider />
                        <ListItem key="close" sx={{position:'fixed', bottom:0, background:'#e8e8e8', padding:'0', margin:'0' }}>
                            <ListItemButton sx={{textAlign:'center', padding:'20px'}}>  
                                <ListItemText primary="Close" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>

            <div className="header">
                
                {/* Mobile Header */}
                <Grid container columnGap={0} justifyContent="center" alignItems="center" sx={{display:{xs:'flex', md:'none'}}}>
                    <Grid item xs={2} sx={{textAlign:'left'}}>
                        <MenuIcon onClick={toggleDrawer(true)} sx={{fontSize:'2.5em', color:"#989898"}}/>     
                    </Grid>
                    <Grid item xs={8} md={6} sx={{textAlign:'center'}}>
                        <img src="images/logo2.svg" draggable='false' style={{maxWidth:'300px', width:'95%'}}/>
                    </Grid>
                    <Grid item xs={2} sx={{textAlign:'right'}}>
                           
                    </Grid>
                  
                </Grid>


                {/* Web Header */}
                <Grid container columnGap={0} justifyContent="center" alignItems="center" sx={{display:{xs:'none', md:'flex'}}}>
                    <Grid item md={2} sx={{textAlign:'left'}} >
                    </Grid>
                    
                    <Grid item md={8} sx={{textAlign:'center'}}>
                        <img src="images/logo2.svg" draggable='false' style={{maxWidth:'350px', width:'80%'}}/>
                    </Grid>
                    
                    <Grid item md={2} sx={{textAlign:'right'}} >
                        <Grid container justifyContent="flex-end" alignItems="center" sx={{cursor:'pointer'}}
                            onClick={(e) => {
                                window.location.href = "mailto:gsecharging@powertraincontrol.com";
                                    e.preventDefault();
                                }}>
                            <Grid item>
                                <EmailIcon/>
                            </Grid>
                            <Grid item sx={{paddingLeft:'10px'}}>
                                <Typography sx={{fontSize:'1.1em', fontWeight:'500'}}>Contact Us</Typography>
                            </Grid>
                        </Grid>  
                    </Grid>
                </Grid>              
            </div>
        </>
    )  
}