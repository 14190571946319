import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import './css/main.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return (
    <div className="App">
      <Router basename={''}>
        <Routes>
          <Route exact path="/" element={<Main/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
